.calculate-yourself {
    text-align: center;
    padding: 20px;
    background: linear-gradient(to right, #141e30, #243b55);
    color: white;
    min-height: 100vh;
}

.calculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

input,
select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
}

button {
    padding: 10px;
    font-size: 18px;
    background-color: #ff7f50;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    background-color: #ff6347;
}

.join-us {
    margin-top: 20px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    text-align: center;
}

.join-us h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #ff7f50;
}

.join-us p {
    font-size: 16px;
    color: white;
}

.join-us button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #ff7f50;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.join-us button:hover {
    background-color: #ff6347;
}

.content {
    display: flex;
}

.callogo {
    width: 5rem;
    height: 5rem;
    cursor: pointer;
}