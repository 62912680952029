section {
  padding: 0rem 6%;
}

.footer .box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  font-size: 20px;
  justify-content: space-between;
}

.footer .box-container .box {
  padding: 1rem;
  flex: auto;
  height: 380px;
  width: 250px;
  font-size: 10px;
}

.footer .box-container .box h3 {
  font-size: 2.5rem;
  color: #fff;
}

.footer .box-container .box p {
  color: #eee;
}

.footer .box-container .box a {
  display: block;
  color: #eee;
}

.footer .box-container .box a:hover {
  color: var(--orange);
  text-decoration: underline;
}

h3,
a {
  text-decoration: none;
  color: white;
  font-size: 13px;
}

li {
  list-style: none;
  color: white;
}

.box>img {
  padding: 0.5rem;
}

.box>img:hover {
  cursor: pointer;
}

.credits {
  text-align: center;

}

@media screen and (max-width: 768px) {
  .footer .box-container {
    flex-direction: column;
    gap: 1rem;
  }

  .footer .box-container .box:nth-child(1) {
    padding: 0px;
    height: 110px;
  }

  .footer .box-container .box:nth-child(2) {
    height: 110px;
    padding: 0px;
    /* margin-top: 5rem; */

  }

  .footer .box-container .box:nth-child(3) {
    height: 110px;
    margin-top: 5rem;
  }

  .credits {
    margin-top: 7rem;
    padding-top: 7rem;
    color: white;
    font-size: 15px;
  }
}

.address {
  font-size: 16px
}

.footer-contact {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

a {
  color: blue;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.credits {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-button {
  font-size: 8px;
  border: 0.5px solid white;
  border-radius: 50%;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px !important;
}

.para {
  color:white
}

.footer-ads {
  width: 100%;
  max-width: 728px; /* Standard ad width */
  margin: 20px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .footer-ads {
    padding: 0 10px;
  }
}