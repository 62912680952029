body {
    font-family: 'Arial', sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
}

.fitness-container {
    max-width: 900px;
    padding: 20px;
    background: linear-gradient(to right, #141e30, #243b55);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.fitness-intro {
    font-size: 1rem;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    text-align: center;
}

.fitness-list {
    list-style-type: none;
    padding: 0;
}

.fitness-item {
    font-size: 1rem;
    background: linear-gradient(135deg, #4f46e5, #6b5ce7);
    color: white;
    padding: 5px;
    border-radius: 10px;
    margin: 10px 0;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.fitness-item:hover {
    background: linear-gradient(135deg, #6b5ce7, #8d79f6);
    transform: scale(1.05);
}

.fitness-item.active {
    font-weight: bold;
    color: white;
}

/* Title inside list item */
.fitness-item-title {
    padding: 10px;
}

/* Expandable Card inside list item */
.fitness-card {
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    display: none;
    opacity: 0;
}

.fitness-item.active .fitness-card {
    display: block;
    opacity: 1;
}

.fitness-title {
    font-size: 1.5rem;
    color: #4f46e5;
    margin-bottom: 10px;
}

.fitness-text {
    font-size: 1rem;
    color: #374151;
    line-height: 1.6;
    margin-bottom: 15px;
    display: inline-grid;
}

strong {
    color: #111827;
    font-weight: 600;
}

.logo {
    width: 5rem;
    height: 4rem;
    display: block;
    cursor: pointer;
}

.logoContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.logoContainer h2 {
    text-decoration: underline;
    color: white;
}

#expandedspan {
    font-size: small;
}