.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: relative;
    z-index: 100;
}

.logo {
    width: 5rem;
    height: 5rem;
}

.menu-icon {
    display: none;
    cursor: pointer;
}

.menu-icon img {
    width: 2rem;
    height: 2rem;
}

/* Default Menu for Desktop */
.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    cursor: pointer;
}

.header-menu li a {
    color: white !important;
    text-decoration: none;
    font-weight: bold;
}

.header-menu li:hover a {
    color: var(--orange);
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .menu-icon {
        display: block;
    }

    /* Background Overlay */
    .menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        /* Dark overlay */
        backdrop-filter: blur(5px);
        /* Blurred effect */
        z-index: 99;
        display: none;
    }

    /* Show overlay when menu is open */
    .menu-overlay.open {
        display: block;
    }

    .header-menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 40%;
        height: 60vh;
        background-color: var(--appColor);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        z-index: 100;
    }

    .header-menu.open {
        transform: translateX(0);
    }

    .header-menu li a {
        color: white !important;
    }

    /* Disable scrolling when menu is open */
    body.menu-open {
        overflow: hidden;
    }
}