.fitness-tip-container {
  width: 100%;
  max-width: 728px;
  margin: 20px auto;
  padding: 0 20px;
}

.fitness-tip-card {
  background: linear-gradient(135deg, #00b4db, #0083b0);
  border-radius: 15px;
  padding: 20px;
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
}

.fitness-tip-card:hover {
  transform: translateY(-5px);
}

.tip-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.tip-content {
  text-align: center;
  padding: 10px 0;
}

.tip-content h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: 600;
}

.tip-content p {
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
}

.tip-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tip-dots {
  display: flex;
  gap: 8px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
}

.dot.active {
  background-color: white;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .fitness-tip-container {
    padding: 0 10px;
  }

  .tip-content h3 {
    font-size: 1.2rem;
  }

  .tip-content p {
    font-size: 0.9rem;
  }
} 