.hero {
  display: flex;
  justify-content: space-between;
}

.left-h {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 30px;
  bottom: 40px;
  background-color: #25d366;
  font-size: 30px;
  padding-top: 11px;
  color: white;
  font-weight: 500px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.the-best {
  margin-top: 4rem;
  background-color: #393d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

.the-best>span {
  z-index: 2;
}

.the-best>div {
  position: absolute;
  background-color: #f44d16;
  width: 5.4rem;
  height: 80%;
  left: 8px;
  z-index: 1;
  border-radius: 3rem;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}

.hero-text>div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
  display: flex;
  gap: 2rem;
}

.figures>div {
  display: flex;
  flex-direction: column;
}

.figures>div>span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}

.figures>div>span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}

.home-btns {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: normal !important;
  margin-top: 20px;
}

.hero-img {
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
}

.hero-back {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}

.calories {
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 32rem;
  right: 28rem;
}

.calories>img {
  width: 3rem;
}

.calories>div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calories>div> :nth-child(1) {
  color: var(--gray);
}

.calories>div> :nth-child(2) {
  color: white;
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }

  .hero-blur {
    width: 14rem;
  }

  .the-best {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }

  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }

  .hero-text>div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }

  .hero-btns {
    justify-content: center;
  }

  .figures>div>span:nth-of-type(1) {
    font-size: large;
  }

  .figures>div>span:nth-of-type(2) {
    font-size: small;
  }

  .hero-img {
    position: relative;
    width: 15rem;
    left: 4rem;
    top: 0rem;
    align-self: center;
  }

  .hero-back {
    /* position: relative; */
    width: 15rem;
    left: 2rem;
    top: 41rem;
  }
}

.image-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* Adjust spacing between images */
}

.image-container img {
  width: 40px;
  /* Adjust size if needed */
  height: auto;

}

.cbtn {
  background-color: black;
  color: white;
  font-weight: 200;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 0px 30px 0px 50px;
}

/* Desktop View */
.desktop-calories {
  position: absolute;
  top: 32rem;
  right: 28rem;
}

/* Mobile View */
.mobile-calories {
  position: static !important;
  top: auto !important;
  right: auto !important;
}

.jbtn {
  background-color: black;
  color: white;
  font-weight: 200;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 0px 30px 0px 50px;
  padding: 10px;
}