.privacy-policy-container {
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    background-color: #f8f9fa;
}

.privacy-policy {
    max-width: 900px;
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
    color: #2c3e50;
}

h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.6rem;
    margin-top: 20px;
    border-bottom: 2px solid #2c3e50;
    padding-bottom: 5px;
}

h3 {
    font-size: 1.3rem;
    margin-top: 15px;
}

p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

li {
    font-size: 1rem;
    color: #444;
    margin-bottom: 5px;
}

.contact-info {
    background-color: #eef5f9;
    padding: 15px;
    border-left: 4px solid #007bff;
    margin-top: 10px;
}

.contact-us {
    text-align: center;
    margin-top: 20px;
}

.contact-us a {
    color: #007bff;
    text-decoration: none;
}

.contact-us a:hover {
    text-decoration: underline;
}

.effective-date {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
}